
<footer class="relative text-gray-100 dark:text-gray-100 bg-indigo-500 pt-8 pb-6">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-indigo-500 bg-white dark:bg-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container mx-auto px-4">
        <div class="body-font">
            <div
                class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                <div class="flex-grow flex flex-wrap -mb-10 md:mt-0 mt-10 md:text-left text-center">
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4 mb-10">
                        <div class="flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                            <a class="click-tag click-tag flex mb-4 md:mb-0" routerLink="/"
                               id="footer-logo"
                            >
                                <img src="assets/img/logo-white.png" style="width: 100px;height: auto;" class="mx-auto md:mx-0"/>
                            </a>
                        </div>
                    </div>
                    <!--<div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="title-font font-medium text-gray-100 dark:text-gray-100 tracking-widest text-sm mb-3">PLATFORM</h2>
                        <nav class="list-none mb-10">
                            &lt;!&ndash;<li>
                                <a id="footer-" class="hover:underline" href="https://app.tekr.io" target="_blank">Sign-in</a>
                            </li>&ndash;&gt;
                            <li>
                                <a id="footer-organizations" class="click-tag hover:underline" routerLink="/services">Organizations</a>
                            </li>
                            <li>
                                <a id="footer-developers" class="click-tag hover:underline" routerLink="/developers">Developers</a>
                            </li>
                        </nav>
                    </div>-->
                    <!--<div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="title-font font-medium text-gray-100 dark:text-gray-100 tracking-widest text-sm mb-3">SERVICES</h2>
                        <nav class="list-none mb-10">
                            <li>
                                <a id="footer-" class="hover:underline" routerLink="/erp">ERP</a>
                            </li>
                            <li>
                                <a id="footer-" class="hover:underline" routerLink="/saas">SaaS</a>
                            </li>
                            <li>
                                <a id="footer-" class="hover:underline" routerLink="/ai">AI</a>
                            </li>
                            <li>
                                <a id="footer-" class="hover:underline" routerLink="/blockchain">Blockchain</a>
                            </li>
                        </nav>
                    </div>-->
                    <!--<div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="title-font font-medium text-gray-100 dark:text-gray-100 tracking-widest text-sm mb-3">USE CASES</h2>
                        <nav class="list-none mb-10">
                            <li>
                                <a id="footer-" class="hover:underline" routerLink="/saas">SaaS</a>
                            </li>
                            <li>
                                <a id="footer-" class="hover:underline" routerLink="/ai">AI</a>
                            </li>
                            <li>
                                <a id="footer-" class="hover:underline" routerLink="/blockchain">Blockchain</a>
                            </li>
                        </nav>
                    </div>-->

                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="title-font font-medium text-gray-100 dark:text-gray-100 tracking-widest text-sm mb-3">SERVICES</h2>
                        <nav class="list-none mb-10">
                            <li>
                                <a id="footer-consulting" class="click-tag hover:underline" routerLink="de/services">Beratung</a>
                            </li>
                            <li>
                                <a id="footer-development" class="click-tag hover:underline" routerLink="de/services">Entwicklung</a>
                            </li>
                            <!--<li>
                                <a id="footer-funding" class="click-tag hover:underline" routerLink="/services">Funding</a>
                            </li>-->
                            <!--<li>
                                <a id="footer-planning" class="click-tag hover:underline" routerLink="/services">Planning</a>
                            </li>-->
                        </nav>
                    </div>
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="title-font font-medium text-gray-100 dark:text-gray-100 tracking-widest text-sm mb-3">SOLUTIONS</h2>
                        <nav class="list-none mb-10">
                            <li>
                                <a id="footer-saas" class="click-tag hover:underline" routerLink="de/saas">SaaS</a>
                            </li>
                            <li>
                                <a id="footer-ai" class="click-tag hover:underline" routerLink="de/ai">KI-Entwicklung</a>
                            </li>
                            <li>
                                <a id="blockchain-ai" class="click-tag hover:underline" routerLink="de/blockchain">Blockchain</a>
                            </li>
                            <!--<li>
                                <a id="footer-lukaz" class="click-tag hover:underline" href="https://get.lukaz.ai" target="_blank">Lukaz AI</a>
                            </li>-->
                        </nav>
                    </div>
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="title-font font-medium text-gray-100 dark:text-gray-100 tracking-widest text-sm mb-3">UNTERNEHMEN</h2>
                        <nav class="list-none mb-10">
                            <!--<li>
                                <a id="footer-" class="hover:underline" routerLink="/about">About</a>
                            </li>-->
                            <li>
                                <a id="footer-contact" class="click-tag hover:underline" routerLink="de/contact">Kontakt</a>
                            </li>
                            <li>
                                <a id="footer-imprint" class="click-tag hover:underline" routerLink="de/imprint">Impressum</a>
                            </li>
                            <li>
                                <a id="footer-privacy-policy" class="click-tag hover:underline" href="https://license.lukaz.ai/datenschutzerklarung" target="_blank">Datenschutzerklärung</a>
                            </li>
                            <li>
                                <a id="footer-cookie-policy" class="click-tag hover:underline" href="https://license.lukaz.ai/datenschutzerklarung" target="_blank">Cookie-Einstellungen</a>
                            </li>
                            <!--<li>
                                <a id="footer-terms-of-use" class="click-tag hover:underline" href="https://license.lukaz.ai/terms" target="_blank">Terms of Use</a>
                            </li>-->
                        </nav>
                        <!--<h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">PROJECTS</h2>
                        <nav class="list-none mb-10">
                            <li>
                                <a id="footer-" class="text-gray-600 hover:text-gray-800">Github</a>
                            </li>
                            <li>
                                <a id="footer-" class="text-gray-600 hover:text-gray-800">Second Link</a>
                            </li>
                            <li>
                                <a id="footer-" class="text-gray-600 hover:text-gray-800">Third Link</a>
                            </li>
                            <li>
                                <a id="footer-" class="text-gray-600 hover:text-gray-800">Fourth Link</a>
                            </li>
                        </nav>-->
                    </div>
<!--
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        &lt;!&ndash;<h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
                        <nav class="list-none mb-10">
                            <li>
                                <a id="footer-" class="text-gray-600 hover:text-gray-800">First Link</a>
                            </li>
                            <li>
                                <a id="footer-" class="text-gray-600 hover:text-gray-800">Second Link</a>
                            </li>
                            <li>
                                <a id="footer-" class="text-gray-600 hover:text-gray-800">Third Link</a>
                            </li>
                            <li>
                                <a id="footer-" class="text-gray-600 hover:text-gray-800">Fourth Link</a>
                            </li>
                        </nav>&ndash;&gt;
                    </div>
-->
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="bg-white dark:bg-gray-900">
    <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
        <p class="text-gray-500 text-sm text-center sm:text-left">
            © 2025 Tekr GmbH
        </p>
        <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start sm:mr-12">
        <a id="switch-lang" class="cursor-pointer text-gray-500 text-sm hover:underline" [routerLink]="['/']" [queryParams]="{lang: 'en'}">
            EN
        </a>
        <a id="footer-facebook" class="click-tag text-gray-500 ml-3" href="https://www.facebook.com/tekr.eu" target="_blank">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5"
               viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a id="footer-twitter" class="click-tag ml-3 text-gray-500" href="https://twitter.com/tekr_io" target="_blank">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5"
               viewBox="0 0 24 24">
            <path
                d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        <a id="footer-instagram" class="click-tag ml-3 text-gray-500" href="https://www.instagram.com/tekr.io" target="_blank">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
               class="w-5 h-5" viewBox="0 0 24 24">
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
          </svg>
        </a>
        <a id="footer-linkedin" class="click-tag ml-3 text-gray-500" href="https://www.linkedin.com/company/tekr" target="_blank">
          <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"
               class="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
    </div>
</div>
