<section class="text-gray-600 bg-gray-100 body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                Imprint
            </h1>
            <p class="mb-8 leading-relaxed">
                <b>Tekr GmbH</b><br/>
                Sophienstraße 1<br/>
                80333 Munich<br/>
                Germany<br/><br/>
<!--                Registry code: 16172938<br/>-->
<!--                VAT: EE102347182<br/>-->
                VAT: DE368474002<br/><br/>
                <a href="mailto:hello@tekr.de" target="_blank" class="font-bold text-indigo-500 hover:underline">hello@tekr.de</a><br/>
                <a href="tel:+498954194791" target="_blank" class="text-indigo-500 hover:underline">+49 89 5419 4791</a><br/>
            </p>
            <div class="flex w-full md:justify-start justify-center items-end">
                <a [routerLink]="'/contact'"
                    class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                    Contact us
                </a>
            </div>
        </div>
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        </div>
    </div>
</section>
