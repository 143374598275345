import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'tekr-imprint-de',
  templateUrl: './imprint-de.component.html',
  styleUrls: ['./imprint-de.component.css']
})
export class ImprintDeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
      localStorage.setItem('refreshed', 'false')

      if (localStorage.getItem('lang') === 'en') {
          this.router.navigate(['de/imprint'])
      }
  }

}
