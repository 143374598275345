<header class="text-gray-600 bg-white dark:bg-gray-900 body-font sticky top-0 z-50 shadow-2xl">
    <div class="container mx-auto flex flex-wrap px-2 flex-row items-center">
        <a id="header-logo" class="click-tag flex items-center mb-1 md:mx-3 mr-2" routerLink="/">
            <img *ngIf="!darkMode" src="assets/img/logo.png" style="width: 100px;height: auto;"/>
            <img *ngIf="darkMode" src="assets/img/logo-dark-bg.png" style="width: 100px;height: auto;"/>
        </a>
        <nav
            class="mr-auto md:ml-4 flex flex-wrap items-center text-base justify-center">

            <ul class="flex pt-2 -mt-2">


                <!--Toggleable Link-->
                <!--
                                <li class="toggleable hover:bg-indigo-200 hover:text-gray-100 dark:hover:text-gray-100">
                                    <input type="checkbox" value="selected" id="toggle-one" class="toggle-input">
                                    <label for="toggle-one"
                                           class="block cursor-pointer py-6 px-4 lg:p-6 text-sm lg:text-base font-bold">Click to
                                        show</label>
                                    <div role="toggle" class="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-indigo-200">
                                        <div class="container mx-auto w-full flex flex-wrap justify-between mx-2">
                                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 pb-6 pt-6 lg:pt-3">
                                                <h3 class="font-bold text-xl text-gray-100 dark:text-gray-100 text-bold mb-2">Bespin</h3>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Lando
                                                        Calrissian</a>
                                                </li>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Nien
                                                        Nunb</a>
                                                </li>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Mon
                                                        Mothma</a>
                                                </li>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Wedge
                                                        Antilles</a>
                                                </li>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Admiral
                                                        Gial Ackbar</a>
                                                </li>
                                            </ul>
                                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 pb-6 pt-6 lg:pt-3">
                                                <h3 class="font-bold text-xl text-gray-100 dark:text-gray-100 text-bold mb-2">Ahch-To</h3>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Obi-Wan
                                                        Kenobi</a>
                                                </li>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Yoda</a>
                                                </li>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Emperor
                                                        Palpatine</a>
                                                </li>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Han
                                                        Solo</a>
                                                </li>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Anakin
                                                        Skywalker/Darth Vader</a>
                                                </li>
                                            </ul>
                                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 pb-6 pt-6 lg:pt-3">
                                                <h3 class="font-bold text-xl text-gray-100 dark:text-gray-100 text-bold">Coruscant</h3>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Luke
                                                        Skywalker</a>
                                                </li>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Princess
                                                        Leia</a>
                                                </li>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">Chewbacca</a>
                                                </li>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">R2-D2</a>
                                                </li>
                                                <li>
                                                    <a href="#" class="block p-3 hover:bg-indigo-200 text-gray-300 hover:text-gray-100 dark:hover:text-gray-100">C-3PO</a>
                                                </li>
                                            </ul>
                                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-6 pt-6 lg:pt-3">
                                                <h3 class="font-bold text-xl text-gray-100 dark:text-gray-100 text-bold mb-2">Endor</h3>
                                                <li class="pt-3">
                                                    <img src="https://placehold.it/205x172">
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                -->
                <!-- end Toggleable Link-->

                <li class="hoverable hover:bg-indigo-500 hover:text-gray-100 dark:hover:text-gray-100">
                    <a routerLink="/services"
                       id="header-services"
                       class="click-tag relative block p-4 sm:px-4 sm:pt-4 sm:pb-2">
                        Services
                    </a>
                    <div class="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-indigo-500">
                        <div class="container mx-auto w-full flex flex-wrap justify-between">
                            <!--<div class="w-full text-gray-100 dark:text-gray-100 mb-8 text-center">
                                <h2 class="font-bold text-2xl">Listen, why don’t you wait out by the speeder.</h2>
                                <p>our droids. They’ll have to wait outside. We don’t want them here.</p>
                            </div>-->

                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/3 pb-6 pt-6 lg:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
                                        </svg>
                                    </div>
                                    <a href="https://calendly.com/tekr/ai-consulting" target="_blank"
                                       id="header-consulting">
                                        <h3 class="click-tag font-bold text-xl text-gray-100 dark:text-gray-100 text-bold mb-2">
                                            Consulting
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Navigate the complexities of product and software development with ease.
                                    Our specialists assess your requirements, identify optimization areas,
                                    and guide you toward the most efficient and innovative solutions.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a routerLink="contact"
                                       id="header-consulting-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Get in touch
                                    </a>
                                </div>
                            </ul>
                            <!--<ul class="px-4 w-full sm:w-1/2 lg:w-1/4 pb-6 pt-6 lg:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                             stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                                        </svg>
                                    </div>
                                    <h3 routerLink="/contact"
                                        id="header-funding"
                                        class="click-tag font-bold text-xl text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer">
                                        AI-Driven Solutions
                                    </h3>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Revolutionize your workflows with intuitive AI-powered solutions. From content
                                    generation to process automation, our technology transforms how you work.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a routerLink="/contact"
                                       id="header-funding-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Let's talk
                                    </a>
                                </div>
                            </ul>-->
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/3 pb-6 pt-6 lg:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
                                        </svg>
                                    </div>
                                    <h3 routerLink="/services" class="font-bold text-xl text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer">
                                        Development
                                    </h3>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Our expert team of developers and AI engineers is ready to transform your ideas into reality.
                                    From concept to delivery, we specialize in building high-quality MVPs and scaling them to robust platforms tailored to your needs, all in record time.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a routerLink="/services"
                                       id="header-services-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Explore all solutions
                                    </a>
                                </div>
                            </ul>
                            <!--<ul class="px-4 w-full sm:w-1/2 lg:w-1/4 pb-6 pt-6 lg:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                             stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                    </div>
                                    <h3 routerLink="/contact"
                                        id="header-funding"
                                        class="click-tag font-bold text-xl text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer">
                                        Funding
                                    </h3>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Get up to 50% of your project's
                                    budget reimbursed by the EU within days after documentation approval.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a routerLink="/contact"
                                       id="header-funding-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Let's talk
                                    </a>
                                </div>
                            </ul>-->
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/3 border-indigo-300 dark:border-indigo-300 pb-6 pt-6 lg:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-white dark:bg-white flex-shrink-0">
                                        <img src="assets/img/logo-u.png"/>
                                    </div>
                                    <a href="https://lukaz.ai" target="_blank"
                                       class="click-tag"
                                       id="header-platform-services">
                                        <h3 class="font-bold text-xl text-gray-100 dark:text-gray-100 text-bold mb-2">
                                            Lukaz AI
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Harness the power of our proprietary AI tool to revolutionize your workflows.
                                    Use your own data sources to generate or transform content, create tailored templates,
                                    and automate routine tasks, all while maintaining complete data control.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-platform-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Sign-up now
                                    </a>
                                </div>
                            </ul>
                        </div>
                    </div>
                </li>
                <!--end Hoverable Link-->

                <li class="hoverable hover:bg-indigo-500 hover:text-gray-100 dark:hover:text-gray-100">
                    <a routerLink="/services"
                       id="header-solutions"
                       class="click-tag relative block p-4 sm:px-4 sm:pt-4 sm:pb-2">
                        Solutions
                    </a>
                    <div class="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-indigo-500">
                        <div class="container mx-auto w-full flex flex-wrap justify-between mx-2">
                            <!--<div class="w-full text-gray-100 dark:text-gray-100 mb-8 text-center">
                                <h2 class="font-bold text-2xl">Listen, why don’t you wait out by the speeder.</h2>
                                <p>our droids. They’ll have to wait outside. We don’t want them here.</p>
                            </div>-->
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/3 pb-6 pt-6 lg:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"/>
                                        </svg>
                                    </div>
                                    <h3 routerLink="/saas"
                                        id="header-saas"
                                        class="click-tag font-bold text-xl text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer">
                                        SaaS
                                    </h3>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Build scalable, full-stack platforms with our services including mobile apps,
                                    custom APIs, bots, automation, payment integrations, and subscription management.
                                    We'll deliver the tools you need to thrive in the digital age.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a routerLink="/saas"
                                       id="header-saas-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Learn more
                                    </a>
                                </div>
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/3 pb-6 pt-6 lg:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                                        </svg>
                                    </div>
                                    <h3 routerLink="/ai"
                                        id="header-ai"
                                        class="click-tag font-bold text-xl text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer">
                                        AI
                                    </h3>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Empower your business with AI-driven productivity tools that simulate human capabilities.
                                    From text and image generation to AI agents and assistants,
                                    our solutions are designed to transform industries and enhance workflows.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a routerLink="/ai"
                                       id="header-ai-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Learn more
                                    </a>
                                </div>
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/3 pb-6 pt-6 lg:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"/>
                                        </svg>
                                    </div>
                                    <h3 routerLink="/blockchain"
                                        id="header-blockchain"
                                        class="click-tag font-bold text-xl text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer">
                                        Blockchain
                                    </h3>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Embrace the future of secure and decentralized systems. Whether it's Web3 infrastructure
                                    for DAOs, DeFi applications, smart contracts, or NFTs, we provide robust blockchain
                                    solutions to safeguard sensitive data and enable transparency.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a routerLink="/blockchain"
                                       id="header-blockchain-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Learn more
                                    </a>
                                </div>
                            </ul>
                            <!--<ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 pb-6 pt-6 lg:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-white dark:bg-white flex-shrink-0">
                                        <img src="assets/img/logo-u.png"/>
                                    </div>
                                    <a href="https://lukaz.ai" target="_blank"
                                       class="click-tag"
                                       id="header-platform-solutions">
                                        <h3 class="font-bold text-xl text-gray-100 dark:text-gray-100 text-bold mb-2">
                                            Lukaz AI
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Generate or transform content using your own sources as context.
                                    Create custom templates and automate prompts.
                                </p>
                                <div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-solutions-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Sign-up
                                    </a>
                                </div>
                            </ul>-->
                        </div>
                    </div>
                </li>
                <!--end Hoverable Link-->

                <!--Regular Link-->
                <!--<li class="hidden sm:block hover:bg-indigo-500 hover:text-gray-100 dark:hover:text-gray-100">
                    <a routerLink="/developers" class="click-tag relative block p-4 sm:px-4 sm:pt-4 sm:pb-2"
                       id="header-developers">
                        Developers
                    </a>
                </li>-->
            </ul>

        </nav>
        <button id="switchTheme"
                class="click-tag h-10 w-10 m-6 fixed bottom-4 right-0 justify-center items-center focus:outline-none text-gray-500"
                (click)="toggleDarkMode()" title="Switch dark/light mode">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"/>
            </svg>
        </button>
        <a
           id="header-contact-us"
           routerLink="/contact"
           class="click-tag md:inline-flex dark:text-white hidden mr-2 items-center bg-indigo-500 text-gray-100 dark:text-gray-100 dark:text-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-base mt-4 md:mt-0">
            Contact us
        </a>

        <a href="https://lukaz.ai/sign-in" target="_blank"
           id="header-cta"
           class="click-tag md:inline-flex hidden items-center bg-gray-700 text-gray-100 dark:text-indigo-500 border-0 py-1 px-3 focus:outline-none hover:bg-gray-800 rounded text-base mt-4 md:mt-0">
            Lukaz AI
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                 class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
        </a>
    </div>
    <!--
        <div class="bg-gray-200 font-sans leading-normal tracking-normal">

            <nav class="relative bg-white border-b-2 border-gray-300 text-gray-900">
                <div class="container mx-auto flex justify-between">
                    <div class="relative block p-4 lg:p-6 text-xl text-indigo-200 font-bold">
                        <a href="#" class="hover:text-indigo-200 dark:hover:text-indigo-200">Logo</a>
                    </div>
                </div>
            </nav>
        </div>-->
</header>
