import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {Meta} from '@angular/platform-browser';
import {Router} from "@angular/router";

@Component({
    selector: 'tekr-saas-de',
    templateUrl: './saas-de.component.html',
    styleUrls: ['./saas-de.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaasDeComponent implements OnInit {

    constructor(private router: Router, private viewportScroller: ViewportScroller, private metaTagService: Meta,
                private ref: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.metaTagService.updateTag(
            {
                name: 'keywords',
                content: 'OOOii',
            },
            'keywords'
        );
        localStorage.setItem('refreshed', 'false')
        this.ref.markForCheck()

        if (localStorage.getItem('lang') === 'en') {
            this.router.navigate(['saas'])
        }
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
        const position = this.viewportScroller.getScrollPosition();
        position[1] = position[1] - 50;
        this.viewportScroller.scrollToPosition(position);
    }
}
