import {NgModule} from '@angular/core';
import {BrowserModule, Meta} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ErpComponent, DialogContentComponent, SecurePipe} from './erp/erp.component';
import {HomeComponent} from './home/home.component';
import {SaasComponent} from './saas/saas.component';
import {AiComponent} from './ai/ai.component';
import {ContactComponent} from './contact/contact.component';
import {ImprintComponent} from './imprint/imprint.component';
import {AboutComponent} from './about/about.component';
import {CareersComponent} from './careers/careers.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BlockchainComponent} from './blockchain/blockchain.component';
import {FundingSectionComponent} from './funding-section/funding-section.component';
import {PipelineSectionComponent} from './pipeline-section/pipeline-section.component';
import {CareersSectionComponent} from './careers-section/careers-section.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {ContactSectionComponent} from './contact-section/contact-section.component';
import {CareersBrComponent} from './careers-br/careers-br.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {MatDialogModule} from '@angular/material/dialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OrganizationsComponent} from './organizations/organizations.component';
import {ServicesComponent} from './services/services.component';
import {LpComponent} from './lp/lp.component';
import {LpAiComponent} from './lp-ai/lp-ai.component';
import {DevelopersComponent} from './developers/developers.component';
import {DevelopersBrComponent} from './developers-br/developers-br.component';
import {CookiePolicyComponent} from './cookie-policy/cookie-policy.component';
import {TermsOfUseComponent} from './terms-of-use/terms-of-use.component';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {LpErpComponent} from './lp-erp/lp-erp.component';
import {CarouselModule} from "ngx-owl-carousel-o";
import {HomeDeComponent} from "./home-de/home-de.component";
import {SaasDeComponent} from "./saas-de/saas-de.component";
import {AiDeComponent} from "./ai-de/ai-de.component";
import {ContactDeComponent} from "./contact-de/contact-de.component";
import {ImprintDeComponent} from "./imprint-de/imprint-de.component";
import {BlockchainDeComponent} from "./blockchain-de/blockchain-de.component";
import {PipelineSectionDeComponent} from "./pipeline-section-de/pipeline-section-de.component";
import {HeaderDeComponent} from "./header-de/header-de.component";
import {FooterDeComponent} from "./footer-de/footer-de.component";
import {ServicesDeComponent} from "./services-de/services-de.component";
import {RouterModule} from "@angular/router";
import {ContactSectionDeComponent} from "./contact-section-de/contact-section-de.component";
import {CommonModule} from "@angular/common";

@NgModule({
    declarations: [
        AppComponent,
        OrganizationsComponent,
        ServicesComponent,
        ServicesDeComponent,
        LpComponent,
        LpAiComponent,
        LpErpComponent,
        DevelopersComponent,
        DevelopersBrComponent,
        ErpComponent,
        DialogContentComponent,
        HomeComponent,
        HomeDeComponent,
        SaasComponent,
        SaasDeComponent,
        AiComponent,
        AiDeComponent,
        ContactComponent,
        ContactDeComponent,
        ImprintComponent,
        ImprintDeComponent,
        AboutComponent,
        CareersComponent,
        BlockchainComponent,
        BlockchainDeComponent,
        FundingSectionComponent,
        PipelineSectionComponent,
        PipelineSectionDeComponent,
        CareersSectionComponent,
        HeaderComponent,
        HeaderDeComponent,
        FooterComponent,
        FooterDeComponent,
        ContactSectionComponent,
        ContactSectionDeComponent,
        CareersBrComponent,
        PrivacyPolicyComponent,
        CookiePolicyComponent,
        TermsOfUseComponent,
        SecurePipe
    ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatMenuModule,
    FormsModule,
    CarouselModule,
    CarouselModule,
    RouterModule,
    CommonModule
  ],
    providers: [Meta],
    bootstrap: [AppComponent]
})
export class AppModule {
}
