import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'tekr-pipeline-section-de',
    templateUrl: './pipeline-section-de.component.html',
    styleUrls: ['./pipeline-section-de.component.css']
})
export class PipelineSectionDeComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    goToLink(link): any {
        window.open(link);
    }
}
