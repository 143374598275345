<section class="text-gray-600 bg-white dark:bg-gray-900 md:bg-work-purple md:bg-top body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-6/12 lg:w-4/12 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <!--<h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 max-w-2xl">
                We improve software productivity through artificial intelligence
            </h1>-->
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 max-w-sm">
                Your Partner for Digital Innovation
            </h1>
            <p class="mb-8 leading-relaxed max-w-md">
                We implement and maintain enterprise software so you can focus on growing your business. With Tekr,
                you get the digital transformation that makes your company tech-driven.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <a routerLink="services"
                   id="home-lm-org"
                   class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-indigo-500 rounded text-lg">
                    Explore our services
                </a>
                <a routerLink="contact"
                   id="home-cta-org"
                   class="ml-2 md:ml-4 inline-flex text-gray-100 dark:text-gray-100 bg-indigo-500 py-2 px-6 focus:outline-none hover:bg-indigo-300 rounded text-lg">
                    Let's talk
                </a>
            </div>
        </div>
        <!--<div class="w-full md:w-6/12 lg:w-8/12 items-center text-center">
            <img src="assets/img/screenshots/lukaz-board-1.png" alt="Lukaz Board" class="hidden lg:block mx-auto"/>
            <img src="assets/img/screenshots/lukaz-mobile.png" alt="Lukaz Board" class="lg:hidden mx-auto"/>
        </div>-->
    </div>

</section>

<!--<section class="text-gray-600 bg-indigo-500 body-font md:bg-people-white-inv md:bg-top" id="services">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-indigo-500 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-10 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <h1 class="sm:text-3xl text-center text-2xl font-medium title-font mb-2 text-gray-100 dark:text-gray-100">
                        Innovative Solutions<br/>
                        to Boost Your Success
                    </h1>
                    &lt;!&ndash;                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>&ndash;&gt;
                    &lt;!&ndash;<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>&ndash;&gt;
                </div>
            </div>
        </div>
    </div>
    <div>

        <div class="container px-5 py-5 mx-auto flex flex-wrap">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Consulting</h2>
                            <p class="leading-relaxed text-base">
                                Leverage the expertise of our top IT consultants to streamline your project’s requirements.
                                From initial planning to implementation, we guide you toward the best solutions for your business.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">AI-Driven Tools</h2>
                            <p class="leading-relaxed text-base">
                                Revolutionize your workflows with intuitive AI-powered solutions. From content generation
                                to process automation, our technology transforms how you work.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div>

        <div class="container px-5 py-5 pb-36 mx-auto flex flex-wrap">
            <div class="flex flex-wrap -m-4">
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Software Development</h2>
                            <p class="leading-relaxed text-base">
                                Bring your ideas to life with our expert developers. We build high-quality MVPs, full-stack
                                platforms, and enterprise-level solutions designed for scalability and impact.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4 lg:w-1/2 md:w-full">
                    <div
                        class="flex border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">ISO-Standard Planning</h2>
                            <p class="leading-relaxed text-base">
                                Save time and resources with AI-enhanced project planning and technical documentation
                                that meet international standards.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->

<section class="text-gray-600 bg-indigo-500 body-font md:bg-connections-white-inv md:bg-top" id="solutions">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-indigo-500 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 pt-16 pb-10 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <h1 class="sm:text-3xl text-center text-2xl font-medium title-font mb-2 text-gray-100 dark:text-gray-100">
                        Innovative Solutions<br/>
                        to Boost Your Success
                    </h1>
                    <!--                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>-->
                    <!--<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>-->
                </div>
            </div>
        </div>
    </div>

    <div class="relative">
        <div class="container px-5 pb-24 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <div
                        class="flex mb-8 border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Consulting</h2>
                            <p class="leading-relaxed text-base">
                                Leverage the expertise of our top IT consultants to streamline your project's requirements.
                                From initial planning to implementation, we guide you toward the best results for your business.
                            </p>
                        </div>
                    </div>
                    <div
                        class="flex mb-8 border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Software Development</h2>
                            <p class="leading-relaxed text-base">
                                Bring your ideas to life with our expert developers. We build high-quality MVPs, full-stack
                                platforms, and enterprise-level products designed for scalability and impact.
                            </p>
                        </div>
                    </div>
                    <div
                        class="flex mb-20 border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">AI-Driven Applications</h2>
                            <p class="leading-relaxed text-base">
                                Revolutionize your workflows with intuitive AI-powered solutions.
                                From content generation to process automation, our technology transforms how you work.
                            </p>
                        </div>
                    </div>

                    <!--<div
                        class="flex mb-8 border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">ISO-Standard Planning</h2>
                            <p class="leading-relaxed text-base">
                                Save time and resources with AI-enhanced project planning and technical documentation
                                that meet international standards.
                            </p>
                        </div>
                    </div>-->
                    <!--<div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                        <a routerLink="services"
                           id="explore-solutions-cta"
                           class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-indigo-500 rounded text-lg">
                            Explore our solutions
                        </a>
                    </div>-->
                    <!--                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>-->
                    <!--<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>-->
                </div>
            </div>
        </div>
    </div>
</section>

<tekr-contact-section></tekr-contact-section>

<!--<section class="relative text-gray-600 bg-gray-100 md:bg-people-black md:bg-top body-font" id="services">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-gray-100 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 py-10 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 mt-4 lg:mb-0">
                    <h1 class="sm:text-3xl text-gray-900 text-center text-2xl font-medium title-font mb-2 text-gray-100">
                        Our Services
                    </h1>
                    &lt;!&ndash;                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>&ndash;&gt;
                    &lt;!&ndash;<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>&ndash;&gt;
                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">

                </div>
            </div>
        </div>
    </div>

    <div class="relative">
        <div class="container px-5 pb-24 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
                    <div
                        class="flex mb-8 border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Consulting</h2>
                            <p class="leading-relaxed text-base">
                                Leverage the expertise of our top IT consultants to streamline your project’s requirements.
                                From initial planning to implementation, we guide you toward the best solutions for your business.
                            </p>
                        </div>
                    </div>
                    <div
                        class="flex mb-8 border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Development</h2>
                            <p class="leading-relaxed text-base">
                                Achieve rapid results with AI-optimized development workflows.
                                Our global talent pool ensures your MVP is ready within three months, meeting the highest standards.
                            </p>
                        </div>
                    </div>

                    <div
                        class="flex mb-8 border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">AI Solutions</h2>
                            <p class="leading-relaxed text-base">
                                Enhance productivity with AI-powered tools.
                                Automate tasks, generate content, and optimize workflows securely and efficiently.
                            </p>
                        </div>
                    </div>
                    &lt;!&ndash;<div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                        <a routerLink="services"
                           id="explore-solutions-cta"
                           class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-indigo-500 rounded text-lg">
                            Explore our solutions
                        </a>
                    </div>&ndash;&gt;
                    &lt;!&ndash;                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>&ndash;&gt;
                    &lt;!&ndash;<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>&ndash;&gt;
                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">

                </div>
            </div>
        </div>
    </div>
</section>-->

<!--<section class="relative text-gray-100 bg-indigo-500 body-font md:bg-connections-white-inv md:bg-top">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-indigo-500 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">

        </div>
        <div
            class="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="text-gray-100 dark:text-gray-100 title-font sm:text-4xl text-3xl mb-4 font-medium max-w-md">
                Lukaz boosts the career
                of many professionals
            </h1>
            <p class="text-gray-100 dark:text-gray-100 mb-8 leading-relaxed max-w-prose">
                We help people from many fields to improve their performance at work while providing them a safe
                AI-driven environment based on their own trusted sources.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <a href="https://get.lukaz.ai" target="_blank"
                   id="home-lm-dev"
                   class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 dark:bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-100 hover:text-indigo-500 dark:hover:text-indigo-500 rounded text-lg">
                    Learn more
                </a>
                <a href="https://lukaz.ai" target="_blank"
                   id="home-cta-dev"
                   class="ml-2 md:ml-4 inline-flex text-indigo-500 dark:text-indigo-500 bg-gray-100 dark:bg-gray-100 py-2 px-6 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 hover:text-gray-100 dark:hover:text-gray-100 rounded text-lg">
                    Automate stuff
                </a>
            </div>
        </div>
    </div>

</section>-->

<section class="relative text-gray-600 bg-gray-100 body-font">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container mx-auto flex px-5 pt-24 md:flex-row flex-col items-center">
        <div class="flex flex-col w-full mb-10 text-center md:text-left">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                Trusted by Industry Leaders
            </h1>
            <p class="max-w-prose mb-8">
                Our partnerships with global and regional innovators drive success across industries.
            </p>
            <!--<h2 class="mx-auto max-w-prose sm:text-2xl text-xl font-medium title-font mb-4 text-gray-900">
                Some of our partners:
            </h2>-->
        </div>
    </div>

    <div class="container mx-auto flex px-5 pb-24 md:flex-row flex-col items-center">
        <div class="flex flex-col md:flex-row w-full mb-10 gap-8">
            <div class="text-center md:text-left">
                <img src="assets/img/partners/terra.png" alt="Terra" class="mx-auto rounded-xl shadow-xl"/>
                <h2 class="leading-relaxed mt-4 italic">
                    Wortmann AG
                </h2>
            </div>
            <div class="text-center md:text-left">
                <img src="assets/img/partners/microsoft.png" alt="Microsoft" class="mx-auto rounded-xl shadow-xl"/>
                <h2 class="leading-relaxed mt-4 italic">
                    Microsoft Corporation
                </h2>
            </div>
            <div class="text-center md:text-left">
                <img src="assets/img/partners/eduplaces.png" alt="Eduplaces" class="mx-auto rounded-xl shadow-xl"/>
                <h2 class="leading-relaxed mt-4 italic">
                    Eduplaces GmbH
                </h2>
            </div>
            <div class="text-center md:text-left">
                <img src="assets/img/partners/iserv.png" alt="iServ" class="mx-auto rounded-xl shadow-xl"/>
                <h2 class="leading-relaxed mt-4 italic">
                    iServ GmbH
                </h2>
            </div>
        </div>
    </div>
</section>

<section class="relative bg-gray-900 md:bg-top body-font text-center" id="testimonials">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>

    <div class="container px-5 py-24 mx-auto text-center">
        <div class="max-w-prose text-center mx-auto mb-12">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                    <div class="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
                            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                    </div>
                    <p class="leading-relaxed text-lg text-gray-200">
                        The easy integration of Tekr's innovative AI solutions into our software package, partnered with
                        Microsoft, showcases their value.
                    </p>
                    <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
                    <h2 class="text-gray-100 font-medium title-font tracking-wider text-sm">ANDREAS BARKOWSKI</h2>
                    <p class="text-gray-400 mb-8">Sales Director of Wortmann AG</p>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
                            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                    </div>
                    <p class="leading-relaxed text-lg text-gray-200">
                        Tekr helped us build the core of our AI-powered software that improves the customer experience
                        for giants like Audi, P&G and Coop.
                    </p>
                    <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
                    <h2 class="text-gray-100 font-medium title-font tracking-wider text-sm">MARC FUNK</h2>
                    <p class="text-gray-400 mb-8">CEO of Frontnow GmbH</p>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
                            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                    </div>
                    <p class="leading-relaxed text-lg text-gray-200">
                        MyDose is today a successful wellness app thanks to great innovations, some of them brought by Tekr's highly qualified team.
                    </p>
                    <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
                    <h2 class="text-gray-100 font-medium title-font tracking-wider text-sm">GUSTAVO MARION</h2>
                    <p class="text-gray-400 mb-8">CEO of MyDose LLC</p>
                </ng-template>
            </owl-carousel-o>

        </div>
    </div>
</section>

<tekr-pipeline-section></tekr-pipeline-section>

<section class="text-gray-100 dark:text-gray-100 bg-indigo-500 body-font">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-indigo-500 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
    </div>
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-6/12 lg:w-4/12 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <!--<h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 max-w-2xl">
                We improve software productivity through artificial intelligence
            </h1>-->
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium max-w-sm">
                Lukaz: Your AI Tool for a Modern Classroom
            </h1>
            <p class="mb-8 leading-relaxed max-w-md">
                Empowering educators and professionals with a secure, AI-driven platform. Lukaz allows users to generate
                and interact with text and image content in an intuitive way, enhancing both learning and workplace productivity.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <a href="https://get.lukaz.ai" target="_blank"
                   id="home-lm-lukaz"
                        class="click-tag inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 dark:bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-100 hover:text-indigo-500 dark:hover:text-indigo-500 rounded text-lg">
                    Learn more
                </a>
                <a href="https://lukaz.ai" target="_blank"
                   id="home-cta-lukaz"
                   class="click-tag ml-2 md:ml-4 inline-flex text-indigo-500 dark:text-indigo-500 bg-gray-100 dark:bg-gray-100 py-2 px-6 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 hover:text-gray-100 dark:hover:text-gray-100 rounded text-lg">
                    Create AI board
                </a>
            </div>
        </div>
        <div class="w-full md:w-6/12 lg:w-8/12 items-center text-center md:ml-12 mb-20">
            <img src="assets/img/screenshots/teaching-3.png" alt="Lukaz Board" class="hidden lg:block mx-auto"/>
            <img src="assets/img/screenshots/teaching-3-mobile.png" alt="Lukaz Board" class="lg:hidden mx-auto"/>
        </div>
    </div>

</section>

<section class="relative text-gray-600 bg-gray-100 md:bg-people-black-inv md:bg-top body-font" id="lukaz">
    <div class="relative">
        <div
            class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style="height: 80px; transform: translateZ(0px);"
        >
            <svg
                class="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    class="text-gray-100 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
        <div class="container px-5 pt-20 pb-12 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <h1 class="sm:text-3xl text-center text-2xl font-medium title-font mb-2 text-gray-900 dark:text-gray-100">
                        Over 600 Organizations across<br/>Germany Are Using Lukaz
                    </h1>
                    <!--                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>-->
                    <!--<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>-->
                </div>
            </div>
        </div>
    </div>

    <div class="relative">
        <div class="container px-5 pb-8 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5">
                    <div
                        class="flex mb-8 border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">AI Boards</h2>
                            <p class="leading-relaxed text-base">
                                Easily create and customize AI-driven content for various tasks.
                            </p>
                        </div>
                    </div>
                    <div
                        class="flex mb-8 border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Cloud & On-Premise</h2>
                            <p class="leading-relaxed text-base">
                                Keep your data secure with local or hybrid deployment solutions.
                            </p>
                        </div>
                    </div>
                    <div
                        class="flex mb-8 border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Trusted Sources</h2>
                            <p class="leading-relaxed text-base">
                                Ensure accurate outputs by integrating your own data sources as context.
                            </p>
                        </div>
                    </div>

                    <!--<div
                        class="flex mb-8 border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                        <div
                            class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                 stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                            </svg>
                        </div>
                        <div class="flex-grow">
                            <h2 class="text-gray-900 text-lg title-font font-medium mb-3">ISO-Standard Planning</h2>
                            <p class="leading-relaxed text-base">
                                Save time and resources with AI-enhanced project planning and technical documentation
                                that meet international standards.
                            </p>
                        </div>
                    </div>-->
                    <!--<div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                        <a routerLink="services"
                           id="explore-solutions-cta"
                           class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-indigo-500 rounded text-lg">
                            Explore our solutions
                        </a>
                    </div>-->
                    <!--                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>-->
                    <!--<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>-->
                </div>

                <!--<div
                    class="flex mb-8 border-2 rounded-lg border-gray-100 border-opacity-50 p-8 sm:flex-row flex-col bg-white dark:bg-gray-900">
                    <button
                        (click)="goToLink('https://get.lukaz.ai')"
                        id="pipeline-section-cta"
                        class="click-tag flex flex-auto mt-8 mb-10 text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-lg">
                        Learn more about Lukaz
                    </button>
                </div>-->
            </div>

        </div>
        <div class="container px-5 pb-40 mx-auto">
            <div class="flex flex-wrap w-full">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">

                </div>
                <div class="lg:w-1/2 w-full lg:pl-5 mx-auto text-center">
                    <button
                        (click)="goToLink('https://get.lukaz.ai')"
                        id="pipeline-section-cta"
                        class="click-tag text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-lg">
                        Learn more about Lukaz
                    </button>
                    <!--                    <div class="h-1 w-20 bg-indigo-500 rounded"></div>-->
                    <!--<p class="leading-relaxed text-gray-500">We implement and maintain
                    enterprise software so you can focus on growing your business. With tekr, you get the digital
                    transformation that makes your company tech-driven.</p>-->
                </div>
            </div>
        </div>
    </div>
</section>
